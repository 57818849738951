<template>
    <section class="page-section border-top-gray" id="scale-up-qna">
        <v-container>
            <div class="title-wrap v-application align-center">
                <div class="mb-md-16 mb-7">
                    <h3 class="title-18 font-weight-regular color-primary">{{ code == "scale-up-qna" ? "ScaleUP" : "Super Rookie" }}</h3>
                    <h2 class="title-52 font-weight-bold">Q&amp;A</h2>
                </div>
            </div>

            <div class="board-container">
                <div role="table" class="board-table board-table--view">
                    <div role="rowgroup">
                        <div role="row">
                            <div role="columnheader">
                                {{ board.subject }}
                            </div>
                        </div>

                        <div role="row">
                            <div role="cell">
                                <ul class="board-view-data">
                                    <li>작성자: {{ board.writer.name }}</li>
                                    <li>작성일: {{ board.createdAt.toDate() }}</li>
                                    <li>조회수: {{ board.viewCount }}</li>
                                </ul>
                            </div>
                        </div>

                        <div role="row">
                            <div role="cell">
                                <div class="board-content">
                                    <div v-html="board.content"></div>
                                </div>
                            </div>
                        </div>

                        <div role="row">
                            <div role="cell">
                                <div class="board-content">
                                    <div v-if="board.reply" v-html="board.reply"></div>
                                    <div v-else>답변이 등록되지 않았습니다.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="board-buttons">
                <div class="left">
                    <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
                </div>
                <!-- <div v-show="false" class="right"> -->
                <!-- <a class="button button--border" href=""><span>수정</span></a>
                    <a class="button button--border" href=""><span>답글</span></a>
                    <a class="button button--primary" href=""><span>삭제</span></a> -->
                <!-- <a class="button button--border" href=""><span>취소</span></a>
                <a class="button button--primary" href=""><span>확인</span></a> -->
                <!-- </div> -->
            </div>
            <!-- <div>
                </div>
                <div>
                    내용:
                </div>
                <div v-if="!board.isNotice">
                    답변:
                    <div v-if="board.reply" v-html="board.reply"></div>
                    <div v-else>답변이 등록되지 않았습니다.</div>
                </div>
                <div>
                    <button @click="$router.go(-1)">목록보기</button>
                </div>
            </div> -->
        </v-container>
    </section>
</template>

<script>
import CommentList from "../../comment-list.vue";

export default {
    components: {
        CommentList,
    },
    props: {
        code: { type: String, default: null },
        board: { type: Object, default: null },
    },
    data() {
        return {
            data: undefined,
            show: false,
        };
    },
    watch: {
        board() {
            if (this.board) this.show = true;
        },
    },
};
</script>
