var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-section border-top-gray",
    attrs: {
      "id": "scale-up-qna"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "title-wrap v-application align-center"
  }, [_c('div', {
    staticClass: "mb-md-16 mb-7"
  }, [_c('h3', {
    staticClass: "title-18 font-weight-regular color-primary"
  }, [_vm._v(_vm._s(_vm.code == "scale-up-qna" ? "ScaleUP" : "Super Rookie"))]), _c('h2', {
    staticClass: "title-52 font-weight-bold"
  }, [_vm._v("Q&A")])])]), _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s(_vm.board.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v("작성자: " + _vm._s(_vm.board.writer.name))]), _c('li', [_vm._v("작성일: " + _vm._s(_vm.board.createdAt.toDate()))]), _c('li', [_vm._v("조회수: " + _vm._s(_vm.board.viewCount))])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_vm.board.reply ? _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.reply)
    }
  }) : _c('div', [_vm._v("답변이 등록되지 않았습니다.")])])])])])])]), _c('div', {
    staticClass: "board-buttons"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("리스트")])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }